import React from 'react';
import {Container} from 'react-bootstrap';
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../../../components/layout";

const Sanchin = () => {
    return (
        <Layout>
            <h1>Sanchin (Life's breath) - Graham Ravey</h1>
            <div className="libraryImages">
                <StaticImage src="../../../images/sanchin.jpg" alt="Sanchin"/>
            </div>
            <p>When asked what is the most important things we as humans need to survive, people will say food,
                clothes and shelter and nine times out of ten never think about air. And yet without it we would die
                within a matter of seconds.</p>

            <p>Once a martial master was conducting a seminar, he asked his students how long is life. One said 40
                years, another said 70 years, no 80 years said yet another. The master insisted that they were all
                wrong.
                "We can not measure how long life is" said one thoughtful student. "Because we all live to different
                ages".
                The master said Life is as long as this and then he inhaled and exhaled a breath of air.</p>

            <p>Yes, life is now and here right at this moment, not in the past and not in the future. If we put the
                words "now+here" together we get "nowwhere".</p>

            <p>Air is so important to our existence and yet there is no manual given to us at birth on the use and
                abuse of it. Most of us without training (Sanchin) will breathe shallowly in our chests thus never
                using the diaphragm to its potential. Shallow breathing results in stale air being trapped in the
                body leading to all kinds of disease and as we get older we breath even more shallow. Oxygen is an
                oxidiser, it not only destroys bacteria in our body but also helps de-toxify it.</p>

            <h3>Sanchin</h3>

            <p><strong>Sanchin</strong> in Japanese means "Three Battles" between mind and body and breathing being
                the glue that bonds the other two together. With proper breath control we can lead to spiritualism.
                In its own context "Air is Spirit".</p>

            <p>When someone is in high spirits Oxygen is pumping around the body via the blood system rapidly.
                Killing bacteria and de-toxifying every cell, thus making us feel good. <br/>
                Low spirit is quite the opposite and being low spirited for a long time results in disease.
                High and low spirits are triggered by both mind and body, for example: <br/>
                <strong>Mind</strong> - worrying about problems, financially, work place, home equals stress.
                <strong>Body</strong> - Lack of exercise, bad eating habits equals Low Spirit.</p>

            <p><strong>Sanchin</strong> if done correctly with the full one and a half pump action of the groin area
                activates the <strong>"Kundalini"</strong> energy (Hindu Serpent power).
                This is the first Chakra or Meridian on the spinal column and is connected to the beast, or physical
                make up of a human. To ground one to the earth Sanchin has a grounding action in its very "Dynamic
                Tension" nature, same as Shaolin, Flesh and Bones Posture. There is much controversy at the moment
                about how much pressure should be applied to the air being inhaled and exhaled from the body , as
                too much pressure can induce hypertension.</p>

            <p>We are told to regularly practice <strong>Sanchin</strong>, but I agree with the fact that too much
                pressure is bad for you and certainly toned my pressure down a few years after I left Japan. In
                Japan we were told to do it just about until our veins bulged in the forehead and felt dizzy if
                practiced about 3-4 times in a row. This I agree is not good on a regular basis, but I always
                remember the feeling I had after.</p>

            <ul>
                <li>That I could fight anybody</li>
                <li>That I was bullet proof</li>
            </ul>


            <p>Now I realize what was happening to me, my Chi-Kung (as the Chinese say)
                or <strong>Kundalini</strong> had been released by the <strong>Sanchin</strong> Kata practice. Have
                you ever watched a Kung Fu demo of wood or stone breaking, the practitioner will start a rhythmic
                pattern with dynamic tension and timed breathing to induce Chi-Kung to the body just before he
                attempts the break. To ground oneself to the beast certainly helps in these situations. </p>

            <p>So I say practice <strong>Sanchin</strong> with moderate air pressure when regularly training, but in
                circumstances like breaking practice with more pressure just before.</p>

            <p>There is a legend of a Celtic warrior who upon arriving at a battle field by Chariot (Chariots were a
                sign of a strong seasoned warrior) would go into a state of "Inner Rage" just before the battle. His
                body would shake, animal like noises would be heard as his veins bulged and faced reddened. I really
                think this is what the Irish call Berserker mode, but also from an onlookers point of view this
                could describe someone practicing <strong>Sanchin</strong> the "Old Way".</p>

            <p>Maybe in the past people had more of a hard time to live and violent confrontations took more of a
                regular role in ones life, so could <strong>Sanchin</strong> have been practiced hard to survive to
                an age of 40 - 50.</p>

            <p><strong>Sensei Graham Ravey</strong></p>
        </Layout>);
}

export default Sanchin;
